.main-container {
  min-height: 100vh;
  padding: 2rem;
  margin-top: 5%;
}

.form-container {
  max-width: 800px;
  margin: 2rem auto;
  padding: 3rem;
  background: white;
  border-radius: 16px;
  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.06);
}

/* Header Styling */
.form-header {
  text-align: center;
  margin-bottom: 3rem;
}

.form-header h1 {
  font-size: 2.5rem;
  font-weight: 700;
  color: #2d3748;
  margin-bottom: 1rem;
}

.form-header p {
  font-size: 1.1rem;
  color: #718096;
}

/* Tab Navigation */
.tab-container {
  display: flex;
  margin-bottom: 3rem;
  background: #f7f7f7;
  padding: 0.5rem;
  border-radius: 12px;
  gap: 0.5rem;
}

.tab {
  flex: 1;
  padding: 1rem;
  text-align: center;
  font-size: 0.95rem;
  color: #4a5568;
  background: transparent;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.2s ease;
  min-height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tab.active {
  background: #eb8181;
  color: white;
  font-weight: 500;
  box-shadow: 0 2px 8px rgba(235, 129, 129, 0.2);
}

.tab:hover:not(.active) {
  background: rgba(235, 129, 129, 0.08);
}

/* Form Section */
.section {
  display: none;
  opacity: 0;
  transition: all 0.3s ease;
}

.section.active {
  display: block;
  opacity: 1;
}

.question-container {
  margin-bottom: 2.5rem;
}

.question-label {
  font-weight: 600;
  color: #2d3748;
  margin-bottom: 0.75rem;
  font-size: 1rem;
}

/* Form Inputs */
.input-textarea {
  width: 100%;
  min-height: 120px;
  padding: 1rem;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  font-size: 0.95rem;
  background: white;
  transition: all 0.2s ease;
  resize: vertical;
}

.input-textarea:focus {
  border-color: #eb8181;
  box-shadow: 0 0 0 3px rgba(235, 129, 129, 0.1);
  outline: none;
}

.input-select {
  width: 100%;
  padding: 0.75rem 1rem;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  font-size: 0.95rem;
  background-color: white;
  color: #2d3748;
  cursor: pointer;
  transition: all 0.2s ease;
  appearance: none;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='%234A5568' viewBox='0 0 16 16'%3E%3Cpath d='M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 1rem center;
  padding-right: 2.5rem;
}

.input-select:focus {
  border-color: #eb8181;
  box-shadow: 0 0 0 3px rgba(235, 129, 129, 0.1);
  outline: none;
}

/* Rating Emoji Container */
.rating-container {
  display: flex;
  gap: 1.5rem;
  margin-top: 1rem;
  justify-content: center;
}

.rating-item {
  font-size: 2.5rem;
  cursor: pointer;
  color: #ddd;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

.rating-item:hover {
  transform: scale(1.15);
}

.emoji-label {
  font-size: 0.8rem;
  color: #666;
  text-align: center;
  margin-top: 0.5rem;
}

/* Button Layout */
.button-container {
  margin-top: 3rem;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.navigation-buttons {
  display: flex;
  gap: 1rem;
}

/* Button Styles */
.button {
  padding: 1rem 2rem;
  font-size: 1rem;
  font-weight: 500;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease;
  min-width: 150px;
  text-align: center;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}

.next-button {
  background: #eb8181;
  color: white;
}

.next-button:hover {
  background: #e66e6e;
}

.back-button {
  background: #f0f0f0;
  color: #666;
}

.back-button:hover {
  background: #e5e5e5;
}

.submit-button {
  background: #eb8181;
  color: white;
  min-width: 150px;
}

.submit-button:hover {
  background: #e66e6e;
}

/* Button Icon Styles */
.button-icon {
  width: 20px;
  height: 20px;
}

/* Button states */
.button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
  opacity: 0.7;
}

/* File Upload */
.file-upload-label {
  display: inline-flex;
  align-items: center;
  padding: 0.75rem 1.5rem;
  background: #eb8181;
  color: white;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.2s ease;
  font-size: 0.95rem;
  font-weight: 500;
}

.file-upload-label:hover {
  background: #e66e6e;
  transform: translateY(-1px);
}

/* Modal Styling */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 50;
}

.modal-content {
  background: white;
  padding: 2rem;
  border-radius: 12px;
  max-width: 400px;
  width: 90%;
  text-align: center;
  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.1);
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .button-container {
    flex-direction: column;
    gap: 1rem;
  }

  .navigation-buttons {
    width: 100%;
    justify-content: center;
  }

  .button {
    width: 100%;
    max-width: 400px;
  }
}

.file-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1.5rem;
  padding: 1rem;
  border-radius: 8px;
  background: #f7fafc;
  border: 1px solid #e2e8f0;
}

.uploaded-image {
  max-width: 300px;
  height: auto;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
