.section-header {
  padding: 24px;
  background: white;
  border-radius: 16px;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
  margin-bottom: 24px;
}

.section-header h2 {
  font-size: 28px;
  font-weight: 600;
  color: #eb8181;
  margin-bottom: 24px;
}

.section-header h3 {
  margin: 0;
  font-size: 18px;
  font-weight: 600;
}

.edit-btn {
  margin-right: 10px;
  /* Space between the title and the edit button */
}

.delete-btn {
  position: static;
  top: auto;
  right: auto;
  font-size: inherit;
}

.title-display {
  display: flex;
  align-items: center;
  gap: 12px;
}

.title-display h3 {
  font-size: 17px;
  font-weight: 600;
  color: #eb8181;
  margin: 0;
  letter-spacing: 0.3px;
}

.edit-btn,
.delete-btn {
  padding: 8px;
  border-radius: 6px;
  transition: all 0.2s;
}

.edit-btn {
  color: #eb8181;
}

.delete-btn {
  color: #ef4444;
}

.edit-btn:hover:not(:disabled),
.delete-btn:hover:not(:disabled) {
  background: rgba(255, 255, 255, 0.5);
}

.content-list {
  padding: 16px;
}

.faq-section {
  background: white !important;
  border-radius: 12px !important;
  margin-bottom: 16px !important;
  overflow: hidden !important;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05) !important;
  border: 1px solid #f0f0f0 !important;
  transition: all 0.2s ease !important;
}

.section-title {
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
  padding: 16px 20px !important;
  background: #fff4f0 !important;
  border-bottom: 1px solid #ffd4d4 !important;
}

.section-title h3 {
  font-size: 17px !important;
  font-weight: 600 !important;
  color: #eb8181 !important;
  margin: 0 !important;
}

.faq-content {
  background: #fff8f8 !important; /* Very light pink background */
  padding: 20px !important;
  position: relative !important;
  border-bottom: 1px solid #ffd4d4 !important;
  transition: background-color 0.2s !important;
}

.faq-content:hover {
  background: #fff4f4 !important; /* Slightly darker on hover */
}

.faq-content div strong {
  color: #eb8181 !important; /* Pink color for Q: and A: */
  font-weight: 600 !important;
  margin-right: 8px !important;
}

.question {
  font-size: 15px !important;
  font-weight: 500 !important;
  color: #542a33 !important; /* Darker color for better contrast */
  margin-bottom: 12px !important;
  padding-right: 100px !important;
}

.answer {
  color: #666666 !important; /* Darker gray for better readability */
  line-height: 1.6 !important;
  font-size: 15px !important;
}

.content-input {
  background: #fff4f0 !important; /* Match section title background */
  padding: 24px !important;
  border-radius: 8px !important;
  margin: 20px !important;
  border: 1px solid #ffd4d4 !important;
}

.content-input textarea {
  width: 100%;
  padding: 12px 16px;
  border: 1px solid #cbd5e1;
  border-radius: 8px;
  margin-bottom: 16px;
  resize: none;
  font-size: 15px;
  min-height: 44px;
  transition: all 0.2s;
}

.content-input textarea:focus {
  border-color: #eb8181;
  box-shadow: 0 0 0 3px rgba(235, 129, 129, 0.1);
  outline: none;
}

.content-input button {
  width: 100%;
  padding: 12px;
  background: #d95757;
  color: white;
  border: none;
  border-radius: 8px;
  font-weight: 500;
  font-size: 15px;
  transition: all 0.2s;
}

.content-input button:hover:not(:disabled) {
  background: #b94141;
}

.content-controls {
  position: absolute;
  right: 12px;
  bottom: 12px;
  display: flex;
  gap: 8px;
}

.content-controls button {
  padding: 6px;
  border-radius: 6px;
  background: white;
  color: #542a33;
  transition: all 0.2s;
}

.content-controls button:hover:not(:disabled) {
  background: #f1f5f9;
}

.dropdown-container {
  margin-bottom: 24px;
}

.dropdown-container label {
  font-size: 15px;
  font-weight: 500;
  color: #4b5563;
  margin-right: 12px;
}

.faq-dropdown {
  padding: 10px 16px;
  border: 1px solid #cbd5e1;
  border-radius: 8px;
  background: white;
  min-width: 220px;
  color: #374151;
  font-size: 15px;
  transition: all 0.2s;
}

.faq-dropdown:focus {
  border-color: #eb8181;
  box-shadow: 0 0 0 3px rgba(235, 129, 129, 0.1);
  outline: none;
}

.section-input {
  display: flex !important;
  gap: 12px !important;
  margin-bottom: 32px !important;
  align-items: center !important;
}

.section-input input {
  flex: 1 !important;
  padding: 12px 16px !important;
  border: 2px solid #ffd4d4 !important;
  border-radius: 8px !important;
  font-size: 15px !important;
  color: #542a33 !important;
  background: white !important;
  transition: all 0.2s !important;
}

.section-input input:focus {
  border-color: #eb8181 !important;
  box-shadow: 0 0 0 3px rgba(235, 129, 129, 0.1) !important;
  outline: none !important;
}

.section-input input::placeholder {
  color: #9ca3af !important;
}

.section-input button {
  padding: 12px 24px !important;
  background: #eb8181 !important;
  color: white !important;
  border: none !important;
  border-radius: 8px !important;
  font-weight: 500 !important;
  font-size: 15px !important;
  transition: all 0.2s !important;
  cursor: pointer !important;
  display: flex !important;
  align-items: center !important;
  gap: 8px !important;
  white-space: nowrap !important;
}

.section-input button:hover:not(:disabled) {
  background: #d95757 !important;
  transform: translateY(-1px) !important;
}

.section-input button:disabled {
  opacity: 0.6 !important;
  cursor: not-allowed !important;
  transform: none !important;
}

.section-input button svg {
  width: 20px !important;
  height: 20px !important;
}

.faq-sections-list {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.section-controls {
  position: relative;
  display: flex;
  gap: 4px;
}

.section-controls button {
  position: relative;
  padding: 6px;
  z-index: 1;
}

/* Edit Mode Styles */
.edit-section-title {
  display: flex;
  align-items: center;
  gap: 12px;
  width: 100%;
}

.edit-section-title input {
  flex: 1;
  padding: 8px 12px;
  border: 2px solid #eb8181;
  border-radius: 6px;
  font-size: 16px;
  font-weight: 500;
  color: #542a33;
  background: white;
  transition: all 0.2s;
}

.edit-section-title input:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(235, 129, 129, 0.1);
}

/* FAQ Edit Actions */
.faq-edit-actions {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 4px;
  background: rgba(255, 255, 255, 0.7);
  border-radius: 6px;
}

.faq-edit-actions button {
  padding: 6px;
  background: transparent;
  border: none;
  cursor: pointer;
  transition: all 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
}

.faq-edit-actions button:first-child {
  color: #22c55e; /* Save button - green */
}

.faq-edit-actions button:last-child {
  color: #ef4444; /* Cancel button - red */
}

/* Content Edit Mode */
.content-input.editing {
  background: white !important;
  border: none !important;
  margin: 0 !important;
  padding: 20px !important;
  position: relative !important;
}

/* Edit Mode Textarea */
.content-input.editing textarea {
  width: 100% !important;
  padding: 12px 16px !important;
  border: 2px solid #eb8181 !important;
  border-radius: 8px !important;
  background: white !important;
  font-size: 15px !important;
  margin-bottom: 12px !important;
  min-height: 60px !important;
  resize: none !important;
  transition: all 0.2s !important;
}

.content-input.editing textarea:focus {
  outline: none !important;
  box-shadow: 0 0 0 3px rgba(235, 129, 129, 0.1) !important;
  border-color: #d95757 !important;
}

/* Edit Mode Container */
.edit-content-container {
  display: flex !important;
  flex-direction: column !important;
  gap: 16px !important;
}

/* Edit Actions Container */
.edit-actions-container {
  display: flex !important;
  justify-content: flex-end !important;
  gap: 8px !important;
  padding: 4px !important;
  margin-top: 8px !important;
}

/* Edit Mode Actions */
.faq-edit-actions {
  display: flex !important;
  align-items: center !important;
  gap: 8px !important;
  padding: 4px !important;
  background: white !important;
  border-radius: 6px !important;
}

.faq-edit-actions button {
  padding: 8px !important;
  background: transparent !important;
  border: none !important;
  cursor: pointer !important;
  transition: all 0.2s !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  font-size: 18px !important;
}

/* Save Button */
.faq-edit-actions button:first-child {
  color: #16a34a !important;
}

/* Cancel Button */
.faq-edit-actions button:last-child {
  color: #dc2626 !important;
}

.faq-edit-actions button:hover {
  transform: scale(1.1) !important;
  background: rgba(0, 0, 0, 0.05) !important;
}

/* Question/Answer Labels */
.content-input.editing label {
  display: block !important;
  font-weight: 500 !important;
  color: #542a33 !important;
  margin-bottom: 8px !important;
  font-size: 15px !important;
}

/* Add spacing between Q&A */
.content-input.editing .question-group {
  margin-bottom: 16px !important;
}

.content-input.editing .answer-group {
  margin-bottom: 16px !important;
}

/* Title and Controls Container */
.title-controls {
  display: flex;
  align-items: center;
  gap: 12px;
  flex: 1;
}

/* Title Display */
.title-display h3 {
  font-size: 17px;
  font-weight: 600;
  color: #3e1f25;
  margin: 0;
}

/* Action Buttons */
.section-actions {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-left: auto; /* Push to the right */
}

.edit-btn,
.delete-btn {
  padding: 6px !important;
  background: transparent !important;
  border: none !important;
  cursor: pointer !important;
  transition: all 0.2s !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  font-size: 18px !important;
  position: static !important;
  box-shadow: none !important;
}

.edit-btn {
  color: #d95757 !important;
}

.delete-btn {
  color: #ef4444 !important;
}

.edit-btn:hover:not(:disabled),
.delete-btn:hover:not(:disabled) {
  transform: scale(1.1) !important;
  background: rgba(255, 255, 255, 0.8) !important;
}

/* Content action buttons */
.faq-actions {
  position: absolute !important;
  top: 12px !important;
  right: 12px !important;
  display: flex !important;
  gap: 6px !important;
  background: white !important;
  padding: 4px !important;
  border-radius: 6px !important;
  opacity: 0 !important;
  transition: opacity 0.2s !important;
  box-shadow: none !important;
}

.faq-content:hover .faq-actions {
  opacity: 1 !important;
}

/* Confirmation modal styles */
.modalBackdrop {
  position: fixed;
  inset: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 50;
}

.modal {
  background: white;
  padding: 24px;
  border-radius: 12px;
  max-width: 400px;
  width: 90%;
  text-align: center;
}

.modal p {
  font-size: 16px;
  color: #333;
  margin-bottom: 20px;
}

.modalActions {
  display: flex;
  justify-content: center;
  gap: 12px;
  margin-top: 24px;
}

.confirmButton {
  padding: 8px 24px;
  background: #ef4444;
  color: white;
  border-radius: 6px;
  font-weight: 500;
  transition: all 0.2s;
}

.confirmButton:hover {
  background: #dc2626;
}

.cancelButton {
  padding: 8px 24px;
  background: #e5e7eb;
  color: #4b5563;
  border-radius: 6px;
  font-weight: 500;
  transition: all 0.2s;
}

.cancelButton:hover {
  background: #d1d5db;
}

/* Section Controls */
.section-controls button {
  padding: 6px !important;
  background: transparent !important;
  border: none !important;
  color: #d95757 !important;
  cursor: pointer !important;
  transition: all 0.2s !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  box-shadow: none !important;
}

/* FAQ Edit Actions */
.faq-edit-actions button {
  padding: 6px !important;
  background: transparent !important;
  border: none !important;
  cursor: pointer !important;
  transition: all 0.2s !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  font-size: 18px !important;
  box-shadow: none !important;
}

.faq-edit-actions button:first-child {
  color: #22c55e !important; /* Save button */
}

.faq-edit-actions button:last-child {
  color: #ef4444 !important; /* Cancel button */
}

/* Content Actions */
.faq-actions {
  position: absolute !important;
  top: 12px !important;
  right: 12px !important;
  display: flex !important;
  gap: 6px !important;
  background: white !important;
  padding: 4px !important;
  border-radius: 6px !important;
  opacity: 0 !important;
  transition: opacity 0.2s !important;
  box-shadow: none !important;
}

.faq-content:hover .faq-actions {
  opacity: 1 !important;
}

/* Add Content Button */
.content-input button {
  width: 100% !important;
  padding: 12px 24px !important;
  background: #eb8181 !important;
  color: white !important;
  border: none !important;
  border-radius: 8px !important;
  font-weight: 500 !important;
  font-size: 15px !important;
  transition: all 0.2s !important;
  box-shadow: none !important;
  cursor: pointer !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  gap: 8px !important;
}

.content-input button svg {
  width: 20px !important;
  height: 20px !important;
}

.content-input button:hover:not(:disabled) {
  background: #d95757 !important;
  transform: translateY(-1px) !important;
}

.content-input button:disabled {
  opacity: 0.6 !important;
  cursor: not-allowed !important;
  transform: none !important;
}

/* Section Controls Group */
.section-controls-group {
  display: flex !important;
  align-items: center !important;
  gap: 16px !important;
  background: rgba(255, 255, 255, 0.7) !important;
  padding: 4px !important;
  border-radius: 6px !important;
}

/* Move Controls */
.section-controls {
  display: flex !important;
  gap: 4px !important;
  border-right: 1px solid #ffd4d4 !important;
  padding-right: 8px !important;
}

/* Action Buttons Group */
.section-actions {
  display: flex !important;
  align-items: center !important;
  gap: 8px !important;
  padding-left: 8px !important;
}

/* Common Button Styles */
.section-controls button,
.edit-btn,
.delete-btn {
  padding: 6px !important;
  background: transparent !important;
  border: none !important;
  cursor: pointer !important;
  transition: all 0.2s !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  font-size: 18px !important;
}

.section-controls button {
  color: #eb8181 !important;
}

.edit-btn {
  color: #eb8181 !important;
}

.delete-btn {
  color: #ef4444 !important;
}

/* Hover effects */
.section-controls button:hover:not(:disabled),
.edit-btn:hover:not(:disabled),
.delete-btn:hover:not(:disabled) {
  transform: scale(1.1) !important;
  background: rgba(255, 255, 255, 0.8) !important;
}

/* Controls Group */
.controls-group {
  display: flex !important;
  align-items: center !important;
  gap: 8px !important;
  background: rgba(255, 255, 255, 0.7) !important;
  padding: 4px 8px !important;
  border-radius: 6px !important;
}

/* Separator Line */
.controls-separator {
  width: 1px !important;
  height: 20px !important;
  background-color: #ffd4d4 !important;
  margin: 0 4px !important;
}

/* Common Button Styles */
.controls-group button {
  padding: 6px !important;
  background: transparent !important;
  border: none !important;
  cursor: pointer !important;
  transition: all 0.2s !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  font-size: 18px !important;
  color: #eb8181 !important;
}

/* Specific Button Colors */
.controls-group .edit-btn {
  color: #eb8181 !important;
}

.controls-group .delete-btn {
  color: #ef4444 !important;
}

/* Hover Effects */
.controls-group button:hover:not(:disabled) {
  transform: scale(1.1) !important;
  background: rgba(255, 255, 255, 0.8) !important;
}

.controls-group button:disabled {
  opacity: 0.5 !important;
  cursor: not-allowed !important;
}

/* Edit Section Title */
.edit-section-title {
  display: flex !important;
  align-items: center !important;
  gap: 12px !important;
  width: 100% !important;
}

.edit-section-title input {
  flex: 1 !important;
  padding: 8px 12px !important;
  border: 2px solid #eb8181 !important;
  border-radius: 6px !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  color: #542a33 !important;
  background: white !important;
  transition: all 0.2s !important;
}

.edit-section-title input:focus {
  outline: none !important;
  box-shadow: 0 0 0 3px rgba(235, 129, 129, 0.1) !important;
}

/* Edit Actions */
.edit-section-title .faq-edit-actions {
  display: flex !important;
  gap: 8px !important;
  padding: 4px !important;
  background: rgba(255, 255, 255, 0.7) !important;
  border-radius: 6px !important;
}

.edit-section-title .faq-edit-actions button {
  padding: 6px !important;
  color: #eb8181 !important;
  background: transparent !important;
  border: none !important;
  cursor: pointer !important;
  transition: all 0.2s !important;
}

.edit-section-title .faq-edit-actions button:hover:not(:disabled) {
  transform: scale(1.1) !important;
  background: rgba(255, 255, 255, 0.8) !important;
}

/* Delete Button Colors */
.delete-btn,
.controls-group .delete-btn {
  color: #ef4444 !important; /* Brighter red for better visibility */
}

.delete-btn:hover:not(:disabled),
.controls-group .delete-btn:hover:not(:disabled) {
  color: #dc2626 !important; /* Darker red on hover */
  transform: scale(1.1) !important;
  background: rgba(255, 255, 255, 0.8) !important;
}

/* Cancel Button in Edit Mode */
.faq-edit-actions button:last-child,
.edit-section-title .faq-edit-actions button:last-child {
  color: #ef4444 !important; /* Matching delete button color */
}

.faq-edit-actions button:last-child:hover:not(:disabled),
.edit-section-title .faq-edit-actions button:last-child:hover:not(:disabled) {
  transform: scale(1.1) !important;
}

.drag-handle {
  position: absolute !important;
  left: -32px !important;
  top: 50% !important;
  transform: translateY(-50%) !important;
  cursor: grab !important;
  padding: 4px !important;
  color: #eb8181 !important;
  opacity: 0.6 !important;
  transition: all 0.2s ease !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  background: #fff4f0 !important;
  border-radius: 4px !important;
}

.faq-content:hover .drag-handle {
  opacity: 1 !important;
  transform: translateY(-50%) scale(1.1) !important;
}

.drag-handle:hover {
  background: #ffd4d4 !important;
}

.drag-handle:active {
  cursor: grabbing !important;
  background: #ffd4d4 !important;
}

.title-area {
  display: flex !important;
  align-items: center !important;
  gap: 12px !important;
  cursor: pointer !important;
  flex: 1 !important;
  padding: 8px !important;
  border-radius: 6px !important;
  transition: background-color 0.2s !important;
}

.title-area:hover {
  background: rgba(255, 255, 255, 0.5) !important;
}

.collapse-btn {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  color: #eb8181 !important;
  transition: transform 0.2s !important;
}

.highlight {
  background-color: rgba(235, 129, 129, 0.2) !important;
  padding: 2px 0 !important;
  border-radius: 2px !important;
  color: #d95757 !important;
  font-weight: 500 !important;
}

.no-content {
  color: #6e7b91;
}

/* Optional: Add animation */
@keyframes highlightFade {
  from {
    background-color: rgba(235, 129, 129, 0.4);
  }
  to {
    background-color: rgba(235, 129, 129, 0.2);
  }
}

.highlight {
  animation: highlightFade 0.5s ease-out !important;
}
